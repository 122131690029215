/* resets */
:focus {
  outline: none;
  outline-color: none;
  outline-style: none;
  outline-width: 0;
  text-decoration-style: dashed;
  text-decoration-color: var(--secondary);
}

.dropdown-ellipses {
  color: inherit;
}

/* fonts */
/* white label extra */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700;800&display=swap');

/* forms */
/* input */
.input-rounded input,
.input-rounded select {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* checkbox */
.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
  background-color: lightgrey;
}

/* loader */
.loading {
  z-index: 99999999999999999;
}

/* dropdown */
.dropdown:not([ngbdropdown]) {
  z-index: 9999999999 !important;
}

.is-loading:after,
.loader {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  animation: 1s c linear infinite;
  border: 2px solid var(--blue);
  border-bottom-color: transparent;
  border-radius: 50%;
  background: transparent;
}

.is-loading-sm:after,
.loader-sm {
  width: 1rem;
  height: 1rem;
  border-width: 1px;
}

.is-loading-lg:after,
.loader-lg {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 3px;
}

.is-loading {
  position: relative;
  color: transparent !important;
}

.is-loading>* {
  opacity: 0 !important;
}

.is-loading:after {
  position: absolute;
  top: calc(50% - 1.5rem / 2);
  left: calc(50% - 1.5rem / 2);
  content: '';
}

.is-loading-sm:after {
  top: calc(50% - 1rem / 2);
  left: calc(50% - 1rem / 2);
}

.is-loading-lg:after {
  top: calc(50% - 2rem / 2);
  left: calc(50% - 2rem / 2);
}

.is-loading-primary:after,
.loader-primary {
  border-color: var(--primary);
  border-bottom-color: transparent;
}

.btn-primary.is-loading:after {
  border-color: var(--white);
  border-bottom-color: transparent;
}

.is-loading-secondary:after,
.loader-secondary {
  border-color: var(--secondary);
  border-bottom-color: transparent;
}

.btn-secondary.is-loading:after {
  border-color: var(--white);
  border-bottom-color: transparent;
}

.is-loading-success:after,
.loader-success {
  border-color: var(--success);
  border-bottom-color: transparent;
}

.btn-success.is-loading:after {
  border-color: var(--light);
  border-bottom-color: transparent;
}

.is-loading-info:after,
.loader-info {
  border-color: var(--violet);
  border-bottom-color: transparent;
}

.btn-info.is-loading:after {
  border-color: var(--light);
  border-bottom-color: transparent;
}

.is-loading-warning:after,
.loader-warning {
  border-color: #f6c343;
  border-bottom-color: transparent;
}

.btn-warning.is-loading:after {
  border-color: var(--blue);
  border-bottom-color: transparent;
}

.is-loading-danger:after,
.loader-danger {
  border-color: #e52c4b;
  border-bottom-color: transparent;
}

.btn-danger.is-loading:after {
  border-color: var(--light);
  border-bottom-color: transparent;
}

.is-loading-light:after,
.loader-light {
  border-color: #edf2f9;
  border-bottom-color: transparent;
}

.btn-light.is-loading:after {
  border-color: var(--blue);
  border-bottom-color: transparent;
}

.is-loading-dark:after,
.loader-dark {
  border-color: #191e3f;
  border-bottom-color: transparent;
}

.btn-dark.is-loading:after,
.is-loading-white:after,
.loader-white {
  border-color: var(--light);
  border-bottom-color: transparent;
}

.btn-white.is-loading:after {
  border-color: var(--blue);
  border-bottom-color: transparent;
}

@keyframes c {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

/* // loader */

/* Smartcode preview */
.replaced {
  padding: 0 0.2rem;
  color: var(--secondary);
}

/* // Smartcode preview */

/* Welcome message */
.welcome-message {
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 999;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(2px);
}

@media (min-width: 1245px) {
  .welcome-message {
    width: 100%;
  }
}

/* // Welcome message */

/* Modal */
.modal-dialog.modal-dialog-vertical.modal-dialog-vertical-lg {
  max-width: 480px;
}

.modal-backdrop.transparent {
  opacity: 0;
}

.help-body img {
  max-width: 100%;
  height: auto;
}

.modal-dialog:not(.modal-dialog-vertical) {
  /* Clear chat bubble */
  margin-bottom: 100px;
}

/* // Modal */

/* Utils */
.cursor-pointer {
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
}

/* Typeahead */
ngb-typeahead-window button.dropdown-item.active {
  background-color: var(--light);
}

/* Cards */
.card-header {
  height: 55px;
}

.card-body {
  padding: 1.3rem;
}

/* Table */
.table thead th {
  width: auto;
}

.table th,
.table td {
  padding: 0.7rem;
}

/* .table tr.type-indicator {
  border-left: 0.2rem solid var(--background);
}

.table tr.type-indicator:hover {
  border-left-width: 0.5rem;
}

.table tr.type-indicator-invoice {
  border-left-color: var(--primary);
}

.table tr.type-indicator-credit-note {
  border-left-color: var(--warning);
}

.table tr.type-indicator-advance {
  border-left-color: var(--info);
}

.table tr.type-indicator-estimate {
  border-left-color: var(--secondary);
}

.table tr.type-indicator-delivery-note {
  border-left-color: var(--teal);
}

.table tr.type-indicator td:first-child {
  padding-left: 1.3rem !important;
}

.table tr.type-indicator:hover td:first-child {
  padding-left: 1.175rem !important;
} */

.no-hover:hover {
  background-color: inherit !important;
}

.card-table thead th:first-child.shrink,
.card-table tbody td:first-child.shrink {
  padding-right: 0.5rem;
}

.card-table thead th:last-child.shrink,
.card-table tbody td:last-child.shrink {
  padding-left: 0.5rem;
}

.z-999 {
  z-index: 999 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('src/assets/icons/caret.svg') no-repeat right #ddd;
  padding-right: 18px !important;
}

/* Range slider */
.slidecontainer {
  width: 100%;
  /* Width of the outside container */
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #e3ebf6;
  outline: none;
  /* opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s; */
}

.theme-dark .slider {
  background: #244166;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
}

.update-notice .alert {
  padding: 0.35rem 1rem;
  display: inline-block;
}

/* Floating save button */
.save-btn.fixed-bottom {
  padding-left: 75px;
}

.sidebar-hidden-mobile .fixed-bottom.save-btn {
  padding-left: 10px;
}

/* Table row button */
table.table tr td>button {
  line-height: 1.4;
}

.icon-button-toggle>button {
  display: none;
}

.icon-button-toggle:hover>i {
  display: none;
}

.icon-button-toggle:hover>button {
  display: inline-block;
}

@media (min-width: 1025px) {
  .fixed-bottom.save-btn {
    padding-left: 305px;
  }
}

.toast-container .toast {
  border-radius: 0;
}

.dropup>.dropdown-toggle:after {
  line-height: 1rem;
}

.dropdown.no-caret button.dropdown-toggle:after {
  content: '';
}

td.shrink {
  width: 1px;
}

/* Badge */
.badge.badge-search {
  position: absolute;
  top: 12px;
  left: 40px;
}

.form-control.search {
  font-family: monospace;
  font-size: 1.1rem;
}

/*
	Dashkit
*/

.dropdown-ellipses {
  font-size: 1.04rem;
}

.toast-container.toast-top-center .ngx-toastr {
  width: auto;
}

@media (max-width: 575px) {
  .toast-container .toast {
    width: auto;
  }
}

.nav-tabs .nav-item:first-child {
  margin-left: 0.75rem;
}

.container-fluid.wrapper {
  max-width: 1600px;
}

.container-fluid.wrapper.wrapper-wide {
  max-width: none;
}

.chart-wrapper {
  display: block;
  height: 318px;
  width: 100%;
}

.whitespace-pre {
  white-space: pre-wrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-normal {
  white-space: normal;
}

@media (min-width: 576px) {
  .whitespace-sm-pre {
    white-space: pre;
  }

  .whitespace-sm-normal {
    white-space: normal;
  }
}

@media (min-width: 768px) {
  .whitespace-md-pre {
    white-space: pre;
  }

  .whitespace-sm-normal {
    white-space: normal;
  }
}

@media (min-width: 992px) {
  .whitespace-lg-pre {
    white-space: pre;
  }

  .whitespace-sm-normal {
    white-space: normal;
  }
}

@media (min-width: 1200px) {
  .whitespace-xl-pre {
    white-space: pre;
  }

  .whitespace-xl-normal {
    white-space: normal;
  }
}

.dropdown-divider {
  margin: 0.4rem 0;
}

.loading-feedback,
.loading-feedback-sm {
  position: absolute;
}

.loading-feedback {
  right: 9px;
  top: 10px;
}

.loading-feedback-sm {
  right: 18px;
  top: 4px;
}

.loading-feedback-left {
  left: 27px;
}

/* Dark */
/* Fix for theme error in dark mode */
/* .theme-dark .table-hover tr:hover {
  background-color: inherit;
} */

.popup-blocked-bg-image {
  background: url('./assets/img/popup-blocked-help.png');
  height: 225px;
  background-size: contain;
  background-repeat: no-repeat;
}

.popup-blocked-wrap {
  width: 300px;
  pointer-events: all;
}

.popup-blocked-background {
  background-color: #fff;
  background-image: none;
}

.popup-blocked-background.dark {
  /* background-color: #12263f; */
  background-color: var(--dark);
  background-image: none;
}

.pointer {
  cursor: pointer;
}

/* @-moz-document url-prefix() {
  .popup-blocked-bg-image {
    display: none;
  }
  .popup-block-secondary-text {
    display: none;
  }
} */

.progress {
  height: 0.5rem;
}

.progress-bar {
  height: 0.5rem;
}

.drop-zone {
  border: 2px dashed #e3ebf6 !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
}

.drop-zone .content {
  color: rgb(25, 30, 63) !important;
}

/* Modal */
.modal-backdrop {
  background-color: none;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

/* .clear-sidebar {
  left: 294px;
} */

/* .clear-sidebar + .modal {
  left: 294px;
  width: calc(100% - 294px);
} */

/* .sidebar-hidden-mobile .clear-sidebar {
  left: 0px;
} */

/* .sidebar-hidden-mobile .clear-sidebar + .modal {
  left: 0px;
  width: 100%;
} */

.help-body h3 {
  margin-bottom: 0.75rem !important;
  margin-top: 1.5rem !important;
  color: var(--blue) !important;
  font-weight: lighter;
}

.help-body hr {
  margin: 2.25rem 0 !important;
}

/* / Modal */

/* File upload */
.ngx-file-drop__drop-zone {
  border: 2px dashed #e3ebf6 !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
}

.ngx-file-drop__.content {
  color: rgb(25, 30, 63) !important;
}

image-cropper {
  border: 1px solid #eee;
}

.file-upload--uploaded {
  background: white;
}

#logoUploadComponent,
#logoUploadComponent .mb-5,
#logoUploadComponent .file-upload,
#logoUploadComponent #dropZone,
#logoUploadComponent .content {
  height: 100px !important;
}

/* .theme-dark .input-group-append,
.theme-dark .input-group-append .dropdown-toggle {
  background-color: #152e4d;
}

.theme-dark .input-group-append .dropdown-toggle {
  border-color: #1e3a5c;
  color: #d2ddec;
} */

.btn-group-lg>.btn,
.btn-lg {
  font-size: 1.0625rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}

@media (min-width: 768px) {
  .main-content .container-fluid {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
}

/* tootlip */
.tooltip.tooltip-secondary .tooltip-inner {
  background-color: var(--secondary);
  color: var(--light);
}

.tooltip.tooltip-secondary.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-top .arrow::before {
  border-top-color: var(--secondary);
}

.tooltip.tooltip-secondary.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-left .arrow::before {
  border-left-color: var(--secondary);
}

.tooltip.tooltip-secondary.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-right .arrow::before {
  border-right-color: var(--secondary);
}

.tooltip.tooltip-secondary.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-bottom .arrow::before {
  border-bottom-color: var(--secondary);
}

/* // tootlip */

/* Charts dark */
/** * Backgrounds */
.theme-dark {
  /** * Text */
  background: var(--dark);
}

.theme-dark .ngx-charts text {
  fill: var(--white);
}

.theme-dark .ngx-charts .tooltip-anchor {
  fill: var(--white);
}

.theme-dark .ngx-charts .gridline-path {
  stroke: var(--light);
}

.theme-dark .ngx-charts .refline-path {
  stroke: var(--light);
}

.theme-dark .ngx-charts .reference-area {
  fill: var(--light);
}

.theme-dark .ngx-charts .grid-panel.odd rect {
  fill: rgba(255, 255, 255, 0.05);
}

.theme-dark .ngx-charts .force-directed-graph .edge {
  stroke: var(--white);
}

.theme-dark .ngx-charts .number-card p {
  color: var(--white);
}

.theme-dark .ngx-charts .gauge .background-arc path {
  fill: var(--dark);
}

.theme-dark .ngx-charts .gauge .gauge-tick path {
  stroke: var(--gray);
}

.theme-dark .ngx-charts .gauge .gauge-tick text {
  fill: var(--gray);
}

.theme-dark .ngx-charts .linear-gauge .background-bar path {
  fill: var(--dark);
}

.theme-dark .ngx-charts .linear-gauge .units {
  fill: #72809b;
}

.theme-dark .ngx-charts .timeline .brush-background {
  fill: rgba(255, 255, 255, 0.05);
}

.theme-dark .ngx-charts .timeline .brush .selection {
  fill: rgba(255, 255, 255, 0.1);
  stroke: #aaa;
}

.theme-dark .ngx-charts .polar-chart .polar-chart-background {
  fill: var(--dark);
}

.theme-dark .chart-legend .legend-labels {
  background: rgba(255, 255, 255, 0.05) !important;
}

.theme-dark .chart-legend .legend-item:hover {
  color: var(--white);
}

.theme-dark .chart-legend .legend-label:hover {
  color: var(--white) !important;
}

.theme-dark .chart-legend .legend-label .active .legend-label-text {
  color: var(--white) !important;
}

.theme-dark .chart-legend .scale-legend-label {
  color: var(--white);
}

.theme-dark .advanced-pie-legend {
  color: var(--white);
}

.theme-dark .advanced-pie-legend .legend-item:hover {
  color: var(--white) !important;
}

.theme-dark .number-card .number-card-label {
  font-size: 0.8em;
  color: var(--white);
}